.bg-front{background: #f0f8fe;
    border-left: 1px solid #adaeb4;
    padding: 10px;
    min-width: 410px;
    height: 100%;
    .customabs_sec {
        position: absolute;
        right: 0;
    }
}
.app-height{height: 100% !important;}

.time{font-weight: 800;font-size: 11px;}
.chat-window{height: 84%;}
.user-bar {
    background: #f6f6f6;
    padding: 5px 10px 8px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-flow: column wrap;
    color: #000;
}
.user_topTxt {
    display: flex;
    justify-content: space-between;
    
}

.user_midBar {
    display: flex;
    align-items: center;
    padding: 15px 0 0;
}
.mob_topBar {
    display: flex;
}
.mob_topBar {
    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.mob_topIcon {
    color: #1f68d2;
    width: 100%;
    text-align: end;
}
.mobWraper {
    border: 4px solid #F0F8FE;
    border-radius: 35px;
    box-shadow: 0px 0px 5px 4px #ededed;
    margin:0 auto;
    max-width: 93rem;
    width:100%;
    position: fixed;
    right: 4rem;
    top: 5.5rem;
}
.mockchat {
    position: relative;
    color: #283747;
    max-width: 100%;
    margin: 0 auto;
    height: auto;
    line-height: 1.45;
    perspective: 900px;
}
.mockchat .device {
    width: 100%;
    margin: 0 auto;
    padding-top: 200%;
    border-radius: 30px;
    position: relative;
    background: #ffffff;
    transition: all 0.5s;
}
.mockchat .device:before {
    display: block;
    content: "";
    position: absolute;
    height: 4%;
    width: 50%;
    background: #fff;
    top: 10px;
    left: 23%;
    border-radius: 0 0 10px 10px;
    z-index: 3;
    border-top: transparent;
}
.mockchat .device:after { 
    display: block;
    content: "";
    position: absolute;
    height: 1%;
    width: 25%;
    background: #ebebeb;
    border-radius: 100px;
    top: 18px;
    left: 36.5%;
    z-index: 99;
    border-top: transparent;
}
.mockchat .device .screen {
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 13px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: linear-gradient(#dcdedf, #dcdedf);
    border-radius: 20px;
    // overflow: hidden;
    background-size: cover;
}
.app1 {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 1em;
    overflow-y: auto;
    transition: all 0.3s;
    border-radius: 20px;
}
.page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chat-container {
    height: 100%;
}

.container-panel {
    /* border: 0.5px solid #76767637; */
    border: none !important;
 }


.mobBottom_sec {
    background: #f6f6f6;
    padding: 10px 0;
}
.mockchat .replyBtn {
    background: transparent;
 }
.conversation-compose {
    display: flex;
     flex-direction: unset; 
     align-items: unset; 
     overflow: unset; 
     height: unset; 
     width: unset; 
    align-items: center;
    justify-content: space-around;
}

.conversation {
    height: calc(100% - 12px);
    position: relative;
    z-index: 0;
}
.conversation .conversation-container {
    height: calc(100% - 48px);
    overflow-x: hidden!important;
    padding: 0 1px;
    margin-bottom: 15px;
}
.conversation-container::-webkit-scrollbar-thumb {
    background-color: #c9c6c6;
    border: 1px solid #c9c6c6;
    border-radius:10px
}
.conversation-container::-webkit-scrollbar {
    width: 5px;
}
.screen-container {
    height: 100%;
}
.mockchat .device .screen {
   
background: url("../../assets/whatsapp-bg.png") !important ;
    background-size: cover !important;
    background-position: center center !important;
}
.conversation-compose .emoji,.conversation-compose .photo {
    display: unset;
    background: transparent;
    border-radius: unset;
    flex: unset;
    margin-left: unset;
    width: unset;
    text-align: unset;
    position: unset;
  }
  .conversation-compose .send {
    margin: 0;
    padding: 0;
    border: none;
}
.conversation-compose .input-msg {
    border: 0;
    flex: unset;
    font-size: 1.0rem;
    margin: unset;
    outline: unset;
    border-radius: 10px;
    padding: 2px 7px 3px;
    max-width: 9rem;
    width: 100% ;


}

.mobBottom_sec .conversation-compose .input-msg::placeholder {
    padding: 0 2px;
    font-size: 0.75rem;
}
.conversation-compose .photo i {
    display: inline-block; 
    color: #7d8488;
    font-size: 24px;
     transform:unset; 
     position: unset; 
}
.messages.sent {
    /*background: #ffffff;*/
    background: transparent;
    border-radius: unset;
    padding: 10px 5px;
}
.right {
 border-left: 0 !important;
}
.whatsapp-preview .messages.sent {
    background: transparent !important;
}
.messages {
    padding: 0;
}
.mobBottom_sec .emoji i, .mobBottom_sec .photo i {
    font-size: 1rem;
}
button.send:focus {
    outline: none;
}
.conversation-compose .send .circle i {
    font-size: 0.7rem;
     margin-left: -2px; 
}
.conversation-compose .send .circle {
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #008a7c;
    border-radius: 50%;
    color: #fff;
    position: relative;
}
.reply {
    margin: 10px 0 0 !important;
    /*width: 100%;*/
    background: #fff;
    border-radius: 0.7em !important;
    position: relative;
    padding: 10px;
}
.reply > span {
    display: block;
}  
span.headerMedia {
    padding: 0 0 10px;
} 
.mockchat .replyBtn {
    padding: 0;
 }
.bodyText {
    font-size: 0.78rem !important;
    line-height: 1.7;
}
.footerText {
    font-size: 13px;
    font-weight: bolder;
}
.actionBtnPanel button {
    color: #00a5f6 !important;
    background: #ffffff;
    margin: 0 0 3px;
    border-radius: 10px;
}
.clip > .text {
  word-break: break-all !important;
}
.css-h4mz3f {
    border: #fff;
    background: #ffffff;
 }
 .actionBtnPanel i {
    font-size: 0.78rem;
}
.user-bar .name {
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    width: 110px;
}
.user-bar .status {
    display: block;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
}
/*Media Query*/
/*Media Query*/
@media only screen and (max-width: 7560px){
    .mobWraper {
        max-width: 20rem;
        //right: 24rem;
    }

 .avatar img {
    width: 32px;
    height: 27px;
}

}
@media only screen and (max-width: 2560px){
    .mobWraper {
        max-width: 19rem;
        right: 4rem;
        top: 12rem;
    } 


}
@media only screen and (max-width: 1920px) {
  .mobWraper {
    max-width: 19.5rem;
    margin: 0 auto;
    right: 5rem;
    top: 9rem;
   }
   .bg-front {
        height: 100%;
    }

    .mobBottom_sec .emoji i, .mobBottom_sec .photo i {
       font-size: 1.3rem;
    }
    .avatar img {
    width: 43px;
    height: 32px;
    }
    
}

@media only screen and (max-width: 1861px) {
    .chat-area-main {
        max-height: 27rem !important;
    }
    
}
@media only screen and (max-width: 1599px) {
    // .mobWraper {
    //     max-width: 17.5rem;
    //     right: 4rem;
    // }
    .scrollSec {
        max-height: 32rem;
    }
    .mobBottom_sec .emoji i, .mobBottom_sec .photo i {
       font-size: 1rem;
    }
    .chat-area-main {
        max-height: 26rem;
    }
 
    .avatar img {
      width: 25px;
      height: 25px;
    }
    .conversation .conversation-container {
        height: calc(100% - 50px);
      }
}

@media only screen and (max-width: 1367px) {
  
   .user-bar .avatar {
        width: 24px;
        height: 25px;
    }
    .user-bar .name {
        font-size: 14px;
    }
    .user-bar .status {
        font-size: 11px;
    }
    .mob_topIcon span > i {
        font-size: 12px;
    }
    .mob_topIcon span:nth-of-type(1) {
        margin-right: 4px !important;
    }
    .mobWraper {
        max-width: 16.5rem;
    }


}

@media only screen and (max-width: 991px) {
 .iconFont i {
    font-size: 24px !important;
 }
 .user-bar .name {
    margin: 0px 0 0 4px;
 }
 .categorySelection p.font-weight-light.font-small-4 {
    max-width: 30rem;
}

}
@media only screen and (max-width: 775px) {
    .categorySelection p.font-weight-light.font-small-4 {
        max-width: 25rem;
    }   
    
}
@media only screen and (max-width: 610px) {
    .mobWraper {
        position: static;
    }   
    
}