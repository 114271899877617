// Font Family
$roboto: "Lexend Deca", sans-serif;
$noto: "Noto Sans JP", sans-serif;
$OptimalMedium: "Optima nova LT Medium";
// Colors ---------------
$primary-color: #000000;
$secondary-color: #EDDCC0;
$text-color: #35F5C6;
$heading-color: #666;
$bg-color: #363A77;
$bdr-color: #C0C0C0;
$hover-color:#CDB786;
$text-secondary: #BEC2C2;
$white: #ffffff;
$lightgray: #f0f0f0;
$mandy: #e65550;
$brightsun: #ffc849;
$spray: #61d6ef;
$btn-bg: #363A77;
$text-bg: #363a77;
$shadow-bg: #00000029;
$heading-bg: #535353;
$light-bg: #f7f6f6;
// Responsive Variables
$_5xl: "only screen and (min-width: 2000px) and (max-width: 5999px)";
$_3xl: "only screen and (min-width: 1601px) and (max-width: 1999px)";
$xxl: "(min-width: 1600px)";
$xl: "only screen and (min-width: 1200px) and (max-width: 1599px)";

$_2xl: "only screen and (min-width: 1024px) and (max-width: 1439px)";
//$_4xl: "only screen and (min-width: 1350px) and (max-width: 1449px)";


$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm: "(max-width: 767px)";
$lsm: "only screen and (min-width: 576px) and (max-width: 767px)";
$xsm: "(max-width: 575px)";
$mXsm: "only screen and (min-width: 400px) and (max-width: 574px)";
$tiny: "(max-width: 399px)";
$mTiny: "only screen and (min-width: 200px) and (max-width: 398px)";

//spacing
$v-sp-lg: 2rem;
$v-sp-sm: 1rem;


// .main-menu.menu-dark, .main-menu.menu-dark .navigation {
//     background: $mandy;
// }

.main-menu.menu-dark, .main-menu.menu-dark .navigation {
    color: black !important;
    background: #10163a !important;
}

// Navbar selection styles

.main-menu.menu-dark .navigation > li.active > a {
    background: #eef3f2 !important;
    box-shadow: 0 0 10px 1px rgb(214 219 218 / 35%) !important;
    color: black !important;
    border-radius: 4px;
}

.main-menu .navigation li.active a {
    background: #d6dbda !important;
    box-shadow: 0 0 10px 1px rgb(214 219 218 / 35%) !important;
    color: #000000 !important;
}

.main-menu.menu-dark .navigation li a {
    color : #D0D0D0;
    padding : 10px 15px 10px 15px;
    line-height : 1.45;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
    background: #f5f5f5;
    box-shadow: none;
    color: #565656
}

// ========================================
