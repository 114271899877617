body {
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .breadcrumbs-holder {
    display: flex;
    padding: 1rem;
  }

  .header-hld {
    display: flex;
    width: 100%;
  }

  .section-hld {
    display: contents;
    width: 100%;
    height: 100%;

    .nav-bar-hld {
      display: flex;
      flex-direction: column;
      width: 80px;
    }
    .nav-bar-hld:hover{
      width: 300px;
      transition: .4s;
    }

    .main-section {
      display: flex;
      flex-direction: column;
      // width: 100%;
      overflow-y: auto;
      // margin-left: 80px;
      height: 100%;
      .content-hld {
        display: flex;
        //padding: 0 1rem;
        width: 100%;
        height: 100%;
      }
    }
  }

  .footer-hld {
    display: flex;
    width: 100%;
    padding: 1rem 1rem 0 1rem;

    .footer-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0.5rem;
      border-radius: 8px;
      background-color: $light-bg;
      padding: 0.5rem;

      .footer-lft {
        display: flex;
        .lg-txt{
          display: flex;
          font-size: 12px;
        }
      }

      .footer-rt {
        display: flex;
        .lg-txt{
          display: flex;
          font-size: 12px;
          color: $bg-color;
          font-weight: bolder;
        }
      }
    }
  }
}

.settings-view {
  .main-section {
    overflow: hidden !important;
  }
}