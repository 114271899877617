.ws-hld {
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100vh;
  height: 100%;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 0.5rem;
    box-shadow: 0 0 1px $shadow-bg, 3px 4px 8px $shadow-bg;
    z-index: 2;
    background-color: #ffffff !important;
    .head-rt {
      display: flex;

      .icon {
        display: flex;
        margin: 0 0.5rem;
        cursor: pointer;

        img{
          width: 24px;
        }

      }

      .txt {
        font-size: 26px;
        color: #10163a;
        font-weight: bolder;
        white-space: nowrap;
        font-family: 'Lexend Deca';
      }
    }

    .head-center {
      display: flex;
      justify-content: center;
      

      .txt {
        color: $text-bg;
        font-weight: bolder;
        font-size: 18px;
        white-space: nowrap;
        font-family: 'Lexend Deca';

        span {
          font-size: 18px;
          color: #C0C0C0;
          font-weight: lighter;
        }
      }

      .icon {
        margin: 0 0.5rem;
      }
    }

    .head-lft {
      display: flex;
      //width: 27.55%;
      align-items: center;

      .btn-hld {
        display: flex;
        justify-content: end;
        width: 100%;
        font-size: 14px;
        font-weight: bold;

        .btn-outlined {
          background: transparent;
          outline: none;
          border: 1px solid $btn-bg;
          border-radius: 4px;
          padding: 0.3rem 0.7rem;
          color: $text-bg;
          margin: 0 0.5rem;
          font-family: 'Lexend Deca';
        }

        .btn-filled {
          background: $bg-color;
          outline: none;
          border: 1px solid $bg-color;
          border-radius: 4px;
          padding: 0.3rem 0.7rem;
          color: white;
          margin: 0 0.5rem;
          font-family: 'Lexend Deca';
        }
      }
    }
   
  }
  .first_divNone {
    & div:first-child {
      display: none;
    }
  }

  .ws-section {
    display: flex;
    width: 100%;
    //height: 86vh;
    height: 100%;
    overflow-y: scroll;
    justify-content: center;
    align-items: baseline;

    .composer-hld {
      display: flex;
      width: 100%;
      position: relative;
    }
  }
}